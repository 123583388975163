<template>
    <footer class="w-full static lg:bottom-0 lg:left-0 lg:right-0 h-auto w-auto mt-20 text-center lg:flex lg:flex-row justify-center lg:space-x-12 lg:space-y-0 space-y-4 lg:mx-8 mx-2 text-slate-300">
        <div class="inline-block lg:ml-28">
          <p>&copy; 2023 SellerSwat. All rights reserved.</p>
        </div>
        
        <div class="inline-block space-x-4">
          <a href="mailto:hello@sellerswat.com" >Send us an email</a>
          <router-link to="/feedback" >Submit a feedback</router-link>
          <router-link to="/tos">Terms of service</router-link>
        </div>

        <div class="inline-block  ">
          <div class="flex lg:space-x-6 space-x-2">
            <a target="_blank" href="https://facebook.com/sellerswat"><img src="../../public/fb.png" class="w-6" alt="facebook"></a>
            <a target="_blank" href="https://twitter.com/sellerswat_com"><img src="../../public/twitter.png" alt="twitter" class="w-6"></a>
            <a target="_blank" href="https://www.linkedin.com/company/sellerswat"><img src="../../public/linked.png" alt="linkedin" class="w-6"></a>
          </div>
        </div>
    </footer>

</template>

<style scoped>
a:hover{
  color: orange;
}
</style>
