<template>
  <div>
    <dash-header :route="this.$route.path"></dash-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
  
</template>

<script>
import { useDark } from "@vueuse/core";
import DashHeader from './components/DashHeader.vue'
import TheFooter from './components/TheFooter.vue'

useDark()



export default {
  name: 'App',
  components: {
    DashHeader,
    TheFooter
  },
  data(){
    return {
      // isDark: true
    }
  },




}
</script>


<style>

[theme="custom-dark"] {
  background: #16171d;
  color: #fff;
}
/* .dark {
  background: #16171d; 
  color: rgb(236, 238, 243);
} */
.dark {
  background: rgb(15 23 42);
  color: rgb(236, 238, 243);
}

.card {
  width: 70%;
  max-width: 800px;
  border: 1px solid rgb(71 85 105);
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  padding: 20px;
  position: relative;
  /* height: 100vh; */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.grad{
	background: linear-gradient(to right, #eb6134 0%, #ebb434 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

}
</style>
