<template>
  <div class="text-center pb-28">
    <div class="mt-10 bg-orange-500 inline-block py-2 lg:px-8 mx-2 px-2 rounded-md">
      <a href="https://app.sellerswat.com/register" class="text-sm text-slate-200">#1 Product Sales Research tool with 0ver 20 key data points. Try for FREE today!</a>
    </div>
    <p class="inline-block lg:text-6xl text-4xl font-black text-center lg:mt-28 mt-12 w-3/4 grad pb-4">
      Product Research Tools For Amazon Sellers
    </p>
    <p class="pb-8 lg:mx-60 mx-6">Gain competitive advantage over your competitors with our competitor analysis tools. Supporting upto 10 marketplaces including Amazon US, Amazon UK, Amazon Canada and more</p>

    <div class="block space-y-2">
      <a href="https://app.sellerswat.com/register"><button class="px-16 py-4 rounded-md bg-orange-800 text-md font-bold">TRY FOR FREE!</button></a>
      <p>No credit card required</p>
    </div>

   <div class="news-ticker bg-slate-600 w-full py-2 text-white mt-10">
    <ul>
      <li>BSR: 50 📈</li>
      <li>ASIN: B073JYC4XM</li>
      <li>List price: $14.99</li>
      <li>Sale Price: $9.99</li>
      <li>Sellers: 15</li>
      <li>Monthly sales: 5,623 📈</li>
      <li>Monthly rev: $56,173 📈</li>
      <li>Date first available: August 3, 2017</li>
      <li>Image count: 6</li>
      <li>Video count: 3</li>
      <li>Catergory: Micro SD card</li>
      <li>Total ratings: 216,701</li>
      <li>Average ratings: 4.7</li>
      <li>Brand: SanDisk</li>
      <li>Top category: Electronics</li>
      <li>Fulfilment type: 2P</li>
    </ul>
  </div>



    <!-- <div class="inline-block mt-10 mx-4">
      <img src="../../public/mock.png" width="800" alt="" class="rounded-lg">
    </div> -->

    <div class="inline-block lg:flex lg:flex-row lg:justify-center p-4 lg:space-x-16 lg:space-y-0 space-y-4 lg:mx-16 mt-20">
      <div class="lg:basis-1/2 text-center lg:flex lg:items-center lg:justify-left lg:ml-14">
        <div class="">
          <p class="font-bold text-4xl pb-4">Sales Estimator</p>
          <p class="">Get insights into the monthly sales volume and monthly revenue of an Amazon listing. Our estimator tool provide a valuable resource to check the average monthly sales figures of an ASIN, facilitate product launches, and offer a convenient means of analyzing your competitors.
          </p>
          <div class="mt-10">
            <a href="https://app.sellerswat.com/register"><button class="grad border border-slate-200 lg:px-10 px-6 py-2 rounded-lg">Try it now</button></a>
          </div>
        </div>
      </div>
      <div class="mb-12 lg:mb-0 pb-16 lg:basis-1/2">
        <img src="../../public/ssimg1.png" width="400" alt="sellerswat">
      </div>
    </div>



    <!-- <div class="lg:flex lg:items-center mt-36 mb-6">
      <div class="space-y-2">
        <p class="font-bold text-4xl pb-8">Sales Estimator</p>
        <p class="lg:mx-40 mx-4">Get insights into the monthly sales volume and monthly revenue of an Amazon listing. Our estimator tool provide a valuable resource to check the average monthly sales figures of an ASIN, facilitate product launches, and offer a convenient means of analyzing your competitors.
        </p>
      </div>
    </div> -->

    <div class="inline-block lg:flex lg:flex-row lg:justify-center p-4 lg:mx-16 lg:mt-60">
      <div class="mb-6 lg:mb-0 lg:ml-14 lg:basis-1/2">
        <img src="../../public/ssimg2.png" width="400" alt="sellerswat">
      </div>
      <div class="lg:basis-1/2 text-center lg:flex lg:items-center">
        <div class="">
          <p class="font-bold text-4xl pb-4">Sell what customers are actually buying</p>
          <p class="">Get insightful data at what products customers are purchasing after your or your competitor's products. It helps your decision in adding new product with the confidence that it will sell.
          </p>
          <div class="mt-10">
            <a href="https://app.sellerswat.com/register"><button class="grad border border-slate-200 lg:px-10 px-6 py-2 rounded-lg">Try it now</button></a>
          </div>
        </div>
      </div>
    </div>




    <div class="lg:items-center mt-36 mb-6 space-y-10">
      <div class="space-y-2">
        <p class="font-bold text-4xl pb-8">Spy on your competitors stock level</p>
        <p class="lg:mx-40 mx-4">Get competitive by knowing your competitors stock level. Inspect a product stock level giving you ultimate knowledge on when they are about to go out of stock or when they just restocked.</p>
      </div>
      <div class="inline-block">
        <img src="../../public/ssimg3.png" width="400" alt="sellerswat">
      </div>
      <div class="mt-10">
        <a href="https://app.sellerswat.com/register"><button class="grad border border-slate-200 lg:px-10 px-6 py-2 rounded-lg">Try it now</button></a>
      </div>
    </div>


    <!-- <div class="inline-block w-full mb-6 mt-20">
      <p class="text-4xl font-bold">
        FAQ
      </p>
    </div> -->

  
    <!-- <div class="inline-block lg:w-3/4 divide-y-8 divide-solid divide-slate-900">
      <div @click="this.showAnswer1 = !this.showAnswer1" class="inline-block cursor-pointer px-6 py-4 bg-slate-800 w-3/4 text-left">
        <p class="text-md font-bold">Why 4K Render?</p>
      </div>
      <div v-show="showAnswer1" class="inline-block p-6 bg-slate-800 w-3/4 text-left border-t-2 border-slate-600">
        <p>Selling on Amazon can be highly competitive. To stand out in the crowd, you need to present your products in the best possible way. That's where we come in. <br><br>

        Our team include seasoned sellers and designers. We have launched many products on Amazon and we can conclude that having a great listing with high quality visuals and graphics can lead to a successful product launch. So we create stunning 3D models product images for sellers like you, bringing your products to life and making them truly pop. With our service, you can give potential customers a 360-degree view of your product, allowing them to see every detail and angle. This level of immersion can significantly increase engagement and lead to higher conversion rates. <br> <br>

        But that's not all. Our 3D models are also incredibly versatile. You can use them on your own website, social media, and other platforms to showcase your products in a way that stands out from the competition. <br> <br>

        We know that time is money when it comes to Amazon, so we work quickly and efficiently to create high-quality 3D models that meet your specific needs. And our pricing is affordable, making our service accessible to businesses of all sizes. <br> <br>

        Don't settle for ordinary product images that blend in with the crowd. Let us help you elevate your brand and increase sales.</p>
      </div>

      <div @click="this.showAnswer2 = !this.showAnswer2" class="inline-block cursor-pointer px-6 py-4 bg-slate-800 w-3/4 text-left">
        <p class="text-md font-bold">How does it work?</p>
      </div>
      <div v-show="showAnswer2" class="inline-block p-6 bg-slate-800 w-3/4 text-left border-t-2 border-slate-600">
        <p>We work with you from the beginning to the end. We understand your product first, then we create 3D model showing different views and angles. We use Amazon standard image specification. We create all images you need in white background, 4k resolution, 2000px by 2000px square. <br> <br> We also create graphics that show details of the product by adding relevant text on the image, we create lifestyle image when possible to eliminate the need of you investing thousands of dollars for product photography. Our designers will expertly blend your product in with relevant stock photo giving your product a sense of lifestyle use. We also create images for your A+ content. </p>
      </div>

      <div @click="this.showAnswer3 = !this.showAnswer3" class="inline-block cursor-pointer px-6 py-4 bg-slate-800 w-3/4 text-left">
        <p class="text-md font-bold">How long does it take?</p>
      </div>
      <div v-show="showAnswer3" class="inline-block p-6 bg-slate-800 w-3/4 text-left border-t-2 border-slate-600">
        <p>it depends on your product and requirements. Once we receive details of your project, we will give you a reasonable timeline. Average timeline is 1-2 weeks.</p>
      </div>

      <div @click="this.showAnswer4 = !this.showAnswer4" class="inline-block cursor-pointer px-6 py-4 bg-slate-800 w-3/4 text-left">
        <p class="text-md font-bold">I'm interested, How do i get in touch?</p>
      </div>
      <div v-show="showAnswer4" class="inline-block p-6 bg-slate-800 w-3/4 text-left border-t-2 border-slate-600">
        <p>Fill in your details and we will get back to you.</p>
        <p v-show="msg" class="border border-green-600 lg:px-10 lg:py-5 rounded-md max-w-fit text-center mt-4">Your submision has been received successfully.</p>
        <p v-show="err" class="border border-red-600 lg:px-10 lg:py-5 rounded-md max-w-fit text-center mt-4">There has been an error. Please try again later.</p>
        <div class="block mt-5 space-y-4">
          <input type="text" name="name" id="name" v-model="name" class="w-full bg-slate-700 rounded-md border-0 py-1.5 pl-2 pr-20 text-white ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-1 focus:ring-inset focus:ring-orange-400 sm:text-sm sm:leading-6 " placeholder="Your name" />

          <input type="email" name="email" id="email" v-model="email" class="w-full bg-slate-700 rounded-md border-0 py-1.5 pl-2 pr-20 text-white ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-1 focus:ring-inset focus:ring-orange-400 sm:text-sm sm:leading-6 " placeholder="Your email" />

          <textarea name="description" id="description" v-model="description" class="w-full bg-slate-700 rounded-md border-0 py-1.5 pl-2 pr-20 text-white ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-1 focus:ring-inset focus:ring-orange-400 sm:text-sm sm:leading-6 " placeholder="Describe your product" />

           <div>
            <button @click="submitDetails()" class="bg-orange-500 rounded-md text-white text-md my-5 mx-10 px-10 py-2">Submit</button>
          </div>

        </div>
      </div>

    
    </div> -->


  </div>
</template>

<script>
import axios from "axios";
let url = "http://localhost:3000/";
// import TickIcon from '../assets/TickIcon.vue';
export default {
  components: {
    // TickIcon
  },
  data(){
    return{
      showAnswer1: false,
      showAnswer2: false,
      showAnswer3: false,
      showAnswer4: false,
      name: "",
      email: "",
      description: "",
      msg: false,
      err: false
    }
  },

  mounted(){
    const newsTicker = document.querySelector('.news-ticker ul');
    // Clone the first news headline and append it to the end of the list
    const firstItem = newsTicker.querySelector('li');
    newsTicker.appendChild(firstItem.cloneNode(true));
    // Start the ticker animation
    newsTicker.style.animationPlayState = 'running';
  },

  methods:{
    submitDetails(){
      axios.post(`${url}render`, { name: this.name, email: this.email, desc: this.description })
      .then(response => {
        if(response.status == 201) {
          return this.msg = true;
        }else{
          return this.err = true;
        }
      })
    }
  }
}
</script>

<style scoped>

/* .gradient{
  background: -webkit-linear-gradient(rgb(243, 198, 125), rgb(255, 145, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */


.news-ticker {
  overflow: hidden;
}
.news-ticker ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
  animation: ticker 40s linear infinite;
}
.news-ticker li {
  display: inline-block;
  margin-right: 20px;
}
@keyframes ticker {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


</style>