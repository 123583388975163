import { createApp } from 'vue'
import { createRouter, createWebHistory} from 'vue-router'

import App from './App.vue'
import HomePage from './components/HomePage.vue'
import './index.css'

// firebase sdk
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBrn9nlo7GhKYLsLEPPj9bBY-6OGRCmRBs",
  authDomain: "sellerswat-4cdef.firebaseapp.com",
  projectId: "sellerswat-4cdef",
  storageBucket: "sellerswat-4cdef.appspot.com",
  messagingSenderId: "233177377160",
  appId: "1:233177377160:web:fb9717d07806c263a2323d",
  measurementId: "G-BP5S70972R"
};

// Initialize Firebase
const appBase = initializeApp(firebaseConfig);
getAnalytics(appBase);


const router = createRouter({
  history: createWebHistory(),
  routes: [ 
    {path: '/',  component: HomePage, name: '/'}, 
  ]
})

const app = createApp(App)

app.use(router)

app.mount('#app')
