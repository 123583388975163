<template>
  <div class="min-h-full">
    <Disclosure as="nav" class="cc">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img class="h-8 w-8" src="../../public/fav_ss.png" alt="Your Company" />
            </div>
            <div class=" md:block">
              <!-- <div class="ml-10 flex items-baseline space-x-4">
                <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-orange-400 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              </div> -->
            </div>
          </div>
          <div class=" md:block">
            <div class="ml-4 flex items-center md:ml-6 lg:text-md text-sm">
              <div class="flex lg:space-x-6 space-x-4">
                <a href="https://app.sellerswat.com/signin" class="pt-2">Log in</a>
                <!-- <span class="bg-orange-500 lg:px-10 px-6 py-2 rounded-md">Get started</span> -->
                <a href="https://app.sellerswat.com/register"><button class="grad border border-slate-200 lg:px-10 px-6 py-2 rounded-lg">Get started</button></a>
              </div>
            </div>
          </div>
        </div>
      </div>

   
    </Disclosure>
  </div>
</template>

<script>

import { Disclosure } from '@headlessui/vue'
// import { Bars3Icon } from '@heroicons/vue/24/outline'
// import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
// import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl: '../../public/profile.svg',
}
// const navigation = [
//   { name: 'Dashboard', href: '/dashboard', current: true },
//   { name: 'Team', href: '/register', current: false },
//   { name: 'Projects', href: '/signin', current: false },
//   { name: 'Calendar', href: '#', current: false },
//   { name: 'Reports', href: '#', current: false },
// ]
const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
  { name: 'Settings', href: '/profile' },
  { name: 'Sign out', href: '/signout' },
]
export default {
  // props: [ 'active', 'route' ],
  components: {
    Disclosure,
    // DisclosureButton,
    // DisclosurePanel,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    // Bars3Icon, 
    // BellIcon, 
    // XMarkIcon
  },
  data(){
    return{
      user,
      // navigation,
      userNavigation,
      // path: this.route,
      // navigation: [
      //   { name: 'Dashboard', href: '/dashboard', current: false },
      //   { name: 'Sales Estimator', href: '/salesestimator', current: false },
      //   { name: 'Stock Estimator', href: '/stockestimator', current: false },
      //   { name: 'Automate Review', href: '/automatereview', current: false },
      //   { name: '4K Render', href: '/4krender', current: false },
      // ]
    }
  },

  beforeUpdate(){
    for(let i = 0; i < this.navigation.length; i++){
        if(this.navigation[i].href == this.$route.path){
          return this.navigation[i].current = true
        }
      }
  }

  
}
</script>

<style scoped>
.cc{
  background-color: rgb(15 23 42);
}
</style>